<template>
    <a-menu
        style="width: 252px; min-height: 90vh"
        :default-selected-keys="[$route.path]"
        mode="inline"
        theme="light"
        @select="changeMenu">
        <a-menu-item key="/account/info">
            <a-icon type="user" />
            <span>账号信息</span>
        </a-menu-item>
        <a-menu-item key="/account/safeSet">
            <a-icon type="security-scan" />
            <span>安全设置</span>
        </a-menu-item>
        <a-menu-item key="/account/subAccount">
            <a-icon type="setting" />
            <span>子账号设置</span>
        </a-menu-item>
    </a-menu>
</template>

<script>
export default {
    name: "Slider",
    methods: {
        changeMenu(val) {
            this.$router.push(val.key)
        }
    }
}
</script>

<style scoped>

</style>
