var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-menu",
    {
      staticStyle: { width: "252px", "min-height": "90vh" },
      attrs: {
        "default-selected-keys": [_vm.$route.path],
        mode: "inline",
        theme: "light",
      },
      on: { select: _vm.changeMenu },
    },
    [
      _c(
        "a-menu-item",
        { key: "/account/info" },
        [
          _c("a-icon", { attrs: { type: "user" } }),
          _c("span", [_vm._v("账号信息")]),
        ],
        1
      ),
      _c(
        "a-menu-item",
        { key: "/account/safeSet" },
        [
          _c("a-icon", { attrs: { type: "security-scan" } }),
          _c("span", [_vm._v("安全设置")]),
        ],
        1
      ),
      _c(
        "a-menu-item",
        { key: "/account/subAccount" },
        [
          _c("a-icon", { attrs: { type: "setting" } }),
          _c("span", [_vm._v("子账号设置")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }