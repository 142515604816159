import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=e21e20fe&scoped=true&"
import script from "./Account.vue?vue&type=script&lang=js&"
export * from "./Account.vue?vue&type=script&lang=js&"
import style0 from "./Account.vue?vue&type=style&index=0&id=e21e20fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e21e20fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/7Fg_oNHL/1/platform-frontend/management-platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e21e20fe')) {
      api.createRecord('e21e20fe', component.options)
    } else {
      api.reload('e21e20fe', component.options)
    }
    module.hot.accept("./Account.vue?vue&type=template&id=e21e20fe&scoped=true&", function () {
      api.rerender('e21e20fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/Account.vue"
export default component.exports